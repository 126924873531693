@import 'functions';

@mixin btn {
  border: none;
  outline: none;
  border-radius: pixel2rem(4);
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 0.02rem;
  line-height: pixel2rem(34);
  transition: opacity 200ms ease;

  &:not(:disabled):hover {
    cursor: pointer;
    opacity: 0.93;
  }
}

@mixin btn-save {
  @include btn;
  background-color: var(--btn-ok);
}

@mixin btn-cancel {
  @include btn;
  background-color: var(--btn-cancel);
}

@mixin btn-close {
  border: none;
  background-color: transparent;
  outline: none;

  .mat-icon {
    transition: color 200ms ease;
    color: var(--icon-light-color);
  }

  &:hover {
    cursor: pointer;

    .mat-icon {
      color: var(--icon-light-hover-color);
    }
  }

  &:focus {
    outline: none;
  }
}

@mixin widgetScroll {
  ::-webkit-scrollbar {
    $size: pixel2rem(6);
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-track {
    border-radius: pixel2rem(10);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.3);
    border-radius: pixel2rem(5);
  }
}

@mixin loader-spinner($background-color: rgba(0, 0, 0, 0.2)) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  transition: all linear 1s;
  z-index: 3;
}

@mixin mat-icons-colors {
  .mat-icon {
    &.timer {
      color: #ffb01f;
    }

    &.check_circle_outline {
      color: #5e608d;
    }

    &.done_all {
      color: #60b854;
    }

    &.timer_off {
      color: #676767;
    }

    &.remove_circle_outline {
      color: #d73552;
    }
  }
}

@mixin truncate-text($width: auto) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin chartLoader($background-color: #ffffff, $height: 100%) {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  padding-top: calc(#{$height} / 2.5);
  text-align: center;
  font: 500 pixel2rem(14) Roboto;
  color: #1c313a;
  z-index: 1;
}

@mixin stars {
  .stars {
    display: flex;

    &__item {
      color: #ffca00;

      &_inactive {
        color: #bfbdbd;
      }

      > .mat-icon {
        $size: pixel2rem(15);

        width: $size;
        height: $size;
        font-size: $size;
      }
    }
  }
}

@mixin align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
