@use '@angular/material' as mat;

@import '~bootstrap/scss/bootstrap';
// @import '~@aws-amplify/ui-angular/theme.css';

$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';
@import '~@ctrl/ngx-emoji-mart/picker';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

$flag-icon-css-path: '~flag-icon-css/flags' !default;
@import '~flag-icon-css/sass/flag-icon';

@import 'styles/global-styles';
@import 'styles/global-variables';

body {
  min-width: $minWidth;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: var(--background-default-color);
}

/* Angular Material Theme */
@import 'styles/material-palettes.scss';

// Be sure that you only ever include this mixin once!
@include mat.core();

$app-primary: mat.define-palette($mat-primary, 400, 200, 300);
$app-accent: mat.define-palette($mat-accent, 700, 600, 800);
$app-theme: mat.define-light-theme($app-primary, $app-accent);

@include mat.all-component-themes($app-theme);

$app-primary-engageHub: mat.define-palette($mat-primary-engageHub, 500, 200, 300);
$app-accent-engageHub: mat.define-palette($mat-accent-engageHub, 500, 300, 600);
$app-theme-engageHub: mat.define-light-theme($app-primary-engageHub, $app-accent-engageHub);

.engageHub-theme {
  @include mat.all-component-themes($app-theme-engageHub);
}

// Material textarea with matInput matTextareaAutosize hides the bottom of chars like g, p, y
// This is a temp workaround for this issue
// Ref:
// https://github.com/angular/components/issues/9208
// https://github.com/angular/components/issues/9738
.ctn-mat-text-area-workaround {
  padding-top: 0.5em !important;
  padding-bottom: 2.5em !important;
}

.cdk-global-overlay-wrapper > .cdk-overlay-pane {
  position: relative !important;
}

// Inline tabSet tabs with scroll
ngb-tabset ul.nav-tabs {
  display: -webkit-box;
  overflow-y: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  height: 45px;
}
