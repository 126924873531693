@import './global-mixins.scss';
@import './material-palettes.scss';

.mat-tab-group.vertical-tab-group {
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  > .mat-tab-header {
    border-bottom: none;

    > .mat-tab-label-container .mat-tab-list {
      > .mat-tab-labels {
        flex-direction: column;
        padding: 0 6px;

        > .mat-tab-label.mat-tab-label-active {
          border-bottom: 2px solid $secondary-dark-color;
        }
      }

      > .mat-ink-bar {
        display: none;
      }

      .mat-tab-label {
        opacity: 1;
        font: 500 0.75rem Roboto;
        letter-spacing: 0.02rem;
        color: var(--on-subnav);
      }
    }
  }

  > .mat-tab-body-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    > .mat-tab-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      > .mat-tab-body-content {
        overflow: hidden;
        background-color: white;
      }
    }
  }
}

.ctn-icon-middle {
  vertical-align: middle;
}

.ctn-header-icon {
  @extend .ctn-icon-middle;
}

.ctn-full-width {
  width: 100%;
}

.ctn-red-snackbar {
  background: red; // #F44336;
}

.ctn-green-snackbar {
  background: green;
}

.ctn-orange-snackbar {
  background: orange;
}

.ctn-tooltip-red {
  background: #b71c1c;
}

.no-select {
  user-select: none;
}

.btn-action {
  height: 25px;
}

::ng-deep .notification__error {
  background: red;
}

.quill-chat-no-border {
  .ql-toolbar {
    border: 0;
  }

  .ql-container {
    border: 0;
    position: static;
  }
}

.ql-picker-up {
  .ql-picker-options {
    top: 0 !important;
    transform: translateY(-100%) !important;
  }
}

.tabset-height-grow {
  $tabs-height: 45px;

  height: 100%;

  .tab-content {
    height: calc(100% - #{$tabs-height});
  }

  .tab-pane {
    height: 100%;
  }
}

.material-button-with-icon {
  // Important is required only to redefine default material sizes
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
  // Material puts its own focus styles so it is okay to remove default outline
  outline: 0 !important;
  color: #808080;
}

.unreadMessage-badge {
  position: absolute;
  top: -5px;
  right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  background-color: #faff1a;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font: bold 13px Roboto, sans-serif;
  color: #000000;
}

/* Scroll */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-dark-color);
  border-radius: 5px;
}

/* Dropdown */
.dropdown-menu {
  padding: 0;
  margin: 0;
  border-radius: 3px;
  box-shadow: 0px 0px 8px 0px rgba(28, 49, 58, 0.2);
  font: 14px Roboto, sans-serif;
  cursor: default;
}

.dropdown-item {
  display: flex;
  align-content: center;
  padding: 8px;
  font: 14px Roboto, sans-serif;
  color: #000000;

  .mat-icon,
  .fa {
    margin-right: 8px;
    width: 17px;
    height: 17px;
    font-size: 17px;
    color: var(--icon-dark-color);
  }

  .fa {
    padding-left: 2px;
  }

  &:focus,
  &:hover {
    color: var(--secondary-dark-color);
    cursor: pointer;
  }

  &:active {
    background-color: var(--grey-light);
    color: #212529;
  }
}

/* Button */
.btn-save {
  @include btn-save;
}

.btn-cancel {
  @include btn-cancel;
}

.btn-close {
  @include btn-close;
}

.btn-separator {
  display: inline-block;
  width: 1.4rem;
}

/* Widget header with select */
.widget-select {
  margin-left: 0.875rem;

  & .mat-form-field {
    font: normal 0.75rem Roboto;
    letter-spacing: 0.03rem;

    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-form-field-infix {
    padding: 0;
    border: 0;
    width: auto;
  }

  .mat-select {
    width: auto;
  }

  .mat-select-value {
    max-width: unset;
  }

  &.chart-type-select .mat-select-trigger {
    background-color: #dde9ea;
    padding: 4px 6px;
    border-radius: 4px;
  }
}

/* Widget chart */
.ctn-widget-chart .chart-legend {
  .legend-title {
    font: 500 0.75rem Roboto;
  }

  .legend-labels {
    background-color: #ffffff;
  }
}

/* Dialogs */
.challenge-dialog mat-dialog-container {
  padding: 0;
}

.conversation-dialog mat-dialog-container {
  padding: 0.5rem;
}
