@import 'functions';

$minWidth: pixel2rem(992);

$navbar-height: pixel2rem(54);
$sidenav-width: pixel2rem(44);
$subnav-height: pixel2rem(38);

$main-ctn-padding-top: pixel2rem(12);
$main-ctn-padding-bottom: pixel2rem(16);
$main-ctn-height: calc(100vh - #{$navbar-height});
$main-ctn-with-subnav-height: calc(100vh - #{$navbar-height} - #{$subnav-height} - 1px); // 1 - extra
